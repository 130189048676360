<template>
  <div>
    <techprocesses-add></techprocesses-add>
  </div>
</template>

<script>
import TechprocessesAdd from "@/components/ecologist/techprocesses/techprocessesAdd";
export default {
  name: "techprocessesAddView",
  components: { TechprocessesAdd },
};
</script>

<style scoped></style>
